<template>
	<div class="content-tab info-content w-15-percent-cl-1 w-15-percent-cl-6  w-20-percent-cl-4  w-15-percent-cl-5">
        <b-table small :fields="fields" :items="items" responsive="lg" tbody-tr-class="size-14 table-row cursor-pointer" id="terminatedTab" @head-clicked="headClicked" show-empty @row-clicked="single_detail">

	    	<template #head(name)="data">
	    		<div>
	    			<span>{{ data.label }}</span>
			        <span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left: 25px;">
			        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
			        </span>
	    		</div>				        
		    </template>
		    <template #head()="data">
	    		<div class="text-center">
	    			<span>{{ data.label }}</span>
	    		</div>				        
		    </template>
			<template #cell(name)="data">
				<span class="maxThreeRow">{{ data.item.name }}</span>
			</template>
			<template #cell(client_name)="data">
				<span class="text-center maxThreeRow">{{ data.item.client_name }}</span>
			</template>	
			<template #cell(status)="data">
				<span class="d-block text-center">
					<span>Terminated</span>
				</span>
			</template>
			<template #cell(amount)="data">
				<span class="text-center maxThreeRow">{{ data.item.project_value }}</span>
			</template>
			<template #cell(commencement_date)="data">
				<span class="d-block text-center">{{ data.item.commencement_date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
			</template>
			<template #cell(completion_date)="data">
				<span class="d-block text-center">{{ data.item.completion_date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
			</template>
			<template #cell()="data">
				<span class="d-block text-center">{{ data.value }}</span>
			</template>
			<template #empty="scope">
				<template v-if="search2">
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data found</h4>
				</template>
				<template v-else>
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
				</template>
		    </template>

		</b-table>

		<div v-if="this.total > 0" class="paging-cus relative">
			<b-pagination
		      	v-model="current"
		      	:total-rows="total"
		      	:per-page="perPage"
		      	aria-controls="terminatedTab"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		    <div class="limit-per">
				<select v-model="selected" class="cursor-pointer">				   
				    <option v-for="limits in limitpage">{{ limits }}</option>
				</select>			  
		    </div>
		</div>
    </div>
</template>
<script>

	export default {
	  	props: {
            search2: {
                type: String,
                default: null
            }
        },
	  	data() {
		    return {
		    	fields: [
                	{ key: 'name', label: 'project Name' },
			        { key: 'country_name', label: 'Country' },
			        { key: 'status', label: 'Status' },
			        { key: 'client_name', label: 'Client' },
			        { key: 'project_value', label: 'Project Value (SGD)' },
			        { key: 'user_name', label: 'Project Owner' },
			        { key: 'commencement_date', label: 'Start Date' },
			        { key: 'completion_date', label: 'End Date' },
			    ],
		    	items: [],
			    sort: {
			    	field: 'id',
			    	type: 'desc'
			    },
			    perPage: 10,
        		current: 1,
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10'
            }
		},		
		mounted () {
		    this.selected = '10'
		},
		watch: {
			current(val) {
				this.fetchProjectList(this.search2)
			},
			selected(val){
				if(val){
					this.perPage = val
					this.fetchProjectList(this.search2)
				}				
			},
		},
		methods: {
			fetchProjectList(search2){
				this.$store
				.dispatch('project/fetchProjectList', {
					id: this.$route.params.id,
					limit: this.perPage,
					sort: this.sort,
					page: this.current,
					search: search2,
					filter: { 
						"list_search": [ 
							{ "field": "country_id","keyword": this.$route.params.id},
							{ "field": "status","keyword": "3"}
						]
					}
				})
				.then(response => {
					this.items = response.data.data.data
					this.total = response.data.data.total					
				})
				.catch((error) => {
					console.log(error)
				})
			},
			headClicked(key) {
				if(this.sort.field == key) {
					if(this.sort.type == 'asc') this.sort.type = 'desc'
					else this.sort.type = 'asc'
				} else {					
					this.sort.field = key
					this.sort.type = 'desc'
				}
				this.fetchProjectList(this.search2)
			},
			single_detail(item) {
		    	this.$router.push(`/admin/our-portfolio/project/${item.country_id}/edit/${item.id}`)
		  	},
		}
	};	

</script>