<template>
	<section>

		<b-row style="justify-content: space-between; align-items: center" class="mb-2">
			<b-col lg="7">
				<Breadcrumb :labels="labels" />
			</b-col>
			<b-col lg="5">
				<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
					<b-form-group class="mb-0">
						<b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
								<feather-icon icon="SearchIcon" size="17" />
							</b-input-group-prepend>
							<b-form-input type="text" placeholder="Search" @input="searchProjectbyCountry" />
						</b-input-group>
					</b-form-group>
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12">
				<div>
					<div class="btn-action text-right mb-2">
						<template v-if="export_num > -1">
							<b-button type="button" variant="primary" class="px-1 mr-10 py-1 h-auto size-18"
								@click="exportDatabyCountry(export_num)">Export Projects</b-button>
						</template>


						<template v-if="(userData && permission.create)">

							<b-button type="button" variant="primary" class="py-1 h-auto size-18" @click="addProject">Add
								Project</b-button>

						</template>
						<template v-else>
							<b-button type="button" variant="primary" class="py-1 h-auto size-18 btn-disabled-gray"
								disabled>Add Project</b-button>
						</template>

					</div>
					<b-tabs card class="caption-add table-custom tabDetail min-120" v-model="tabIndex">
						<b-tab title="Overview" class="overview-tab">
							<OverviewTab ref="tab_0" :search2="search2" />
						</b-tab>
						<b-tab title="Active" class="active-tab">
							<ActivityTab ref="tab_1" :search2="search2" />
						</b-tab>
						<b-tab title="Completed" class="completed-tab">
							<CompletedTab ref="tab_2" :search2="search2" />
						</b-tab>
						<b-tab title="Developing">
							<DevelopingTab ref="tab_3" :search2="search2" />
						</b-tab>
						<b-tab title="Terminated">
							<TerminatedTab ref="tab_4" :search2="search2" />
						</b-tab>
					</b-tabs>
				</div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

import Breadcrumb from '../partial/Breadcrumb';
import OverviewTab from './tab-detail/OverviewTab';
import ActivityTab from './tab-detail/ActivityTab';
import CompletedTab from './tab-detail/CompletedTab';
import DevelopingTab from './tab-detail/DevelopingTab';
import TerminatedTab from './tab-detail/TerminatedTab';
import globalAdmin from '../model/globalAdmin';
import {
	mapActions,
	mapState
} from 'vuex'
export default {
	mixins: [globalAdmin],
	components: {
		Breadcrumb,
		OverviewTab,
		ActivityTab,
		CompletedTab,
		DevelopingTab,
		TerminatedTab
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			permission: [],
			labels: { "our-portfolio-project-detail": "" },
			tam: [],
			timer: null,
			tabIndex: 0,
			search2: null,
			export_num: -1,
			filter: {}
		}
	},
	watch: {
		search2(val) {
			this.loadDataToTab(val)
		},
		tabIndex: function (val) {
			this.loadDataToTab(this.search2)
			this.export_num = parseInt(val) - 1
		},
		permissions: function (val) {
			this.permission = val
			// console.log(this.permission,'9999999999999')
		},
	},
	created() {
		this.permission = this.checkPermissionOurPortfolio(this.userData, "Project", this.$route.params.project_id, "Project") ?? []
		this.fetchCountryID()

		if (this.$route && this.$route.query && this.$route.query.tab) this.tabIndex = Number(this.$route.query.tab)
	},
	mounted() {
		this.$nextTick(() => {
			this.loadDataToTab(this.search2)
		})
	},
	computed: mapState({
		permissions: state => state.auth.permissionOurPortfolio,
	}),
	methods: {
		...mapActions({
			checkPermisionOurPortfolio: 'auth/checkPermisionOurPortfolio',
		}),
		fetchCountryID() {
			this.$store
				.dispatch('country/fetchCountryID', this.$route.params.id)
				.then(response => {
					this.labels['our-portfolio-project-detail'] = response.data.data.name
					document.title = response.data.data.name + ' - Project Listing - ITEES | Your Global Partner of Choice'
				})
				.catch((error) => {
					console.log(error)
				})
		},
		addProject() {
			this.$router.push(`/admin/our-portfolio/project/${this.$route.params.id}/add`)
		},
		searchProjectbyCountry(val) {
			this.search2 = val
		},
		loadDataToTab(val) {
			this.$refs[`tab_${this.tabIndex}`].currentPage = 1
			this.$refs[`tab_${this.tabIndex}`].fetchProjectList(val)
		},
		exportDatabyCountry(item) {
			if (item < 0) {
				this.filter = { "list_search": [{ "field": "country_id", "keyword": this.$route.params.id }] }
			}
			else {
				this.filter = { "list_search": [{ "field": "country_id", "keyword": this.$route.params.id }, { "field": "status", "keyword": item }] }
			}
			this.$store.dispatch('project/exportCsv', {
				filter: this.filter
			})
				.then(response => {
					const url = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', 'ProjectCountry.csv') //or any other extension
					document.body.appendChild(link)
					link.click()
					// console.log('file download: ', response)
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}
};

</script>

<style lang="scss">
@import "@/assets/scss/_ite_tab_table.scss";
</style>